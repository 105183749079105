.nav_container {
  height: 100%;
  width: 100%;
  background-color: white;
}

.header_block {
  height: 100%;
  width: 65%;
  margin: auto;
}

.header_main {
  float: left;
  width: 100%;
  height: 79%;
  margin-bottom: .25%;
}

.logo {
  float: left;
  background-color: inherit;
  border: 0px solid black;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 34.5%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.logo img{
  height: auto;
  width: 90%;
  padding-left: 5%;
  padding-right: 5%;
}

.header_top {
  float: right;
  height: 100%;
  width: 65%;
}

.logo h2{
  margin: 0 auto;
  padding: 0;
}

.header_top_bar {
  float: right;
  height: 30%;
  width: 100%;

  display: flex;
  align-items: center;
}

.top_nav_side {
  width: 60%;
  height: 100%;
  float: left;
  padding-right: 1%;
  padding-left: 2%;
  min-width: 160px;
}

.top_nav_side nav {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.top_nav {
  width: 100%;
  padding-left: 5%;
  padding-bottom: 2%;
}

.top_nav form{
  float: left;
}

.top_nav ul {
  margin: 0;
  padding: 0;
}

.form-inline { 
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline input{
  width: 120px;
}

.form-inline input[type=submit]{
  width: 80px;
}

.form-inline li{
  width: 50px;
}

.top_nav li {
  width: 100%;
  height: 100%;
  text-align: center;
  min-width: 74px;
}

.top_nav a {
  color: #222;
  text-decoration: none;
  font-size: 10pt;
}

.top_nav a:hover {
  color: darkgrey;
}

.search_eles {
  float: right;
  background-color: lightgrey;
  border: 0px;
  border-radius: 0 0 20px 20px;
  width: 20%;
  height: 100%;
  min-width: 180px;
}

.search_eles form{
  float: left;
  height: 100%;
}

.search_cont_a {
  float: left;
  display: flex;
  align-items: center;
  width: 67.5%;
  padding-left: 7.5%;
  height: 100%;
  padding-right: 2.5%;
  background-color: inherit;
}

.search_cont_b {
  float: left;
  align-items: right;
  display: flex;
  align-items: center;
  width: 22.5%;
  height: 100%;
  background-color: inherit;
}

.search_eles li {
  height: 100%;
  width: 100%;
}

.search_field {
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #aaa;
    border-radius: 5px;
}


.search_button {
    width: auto;
    height: auto;
    text-align: center;
}

.slogan {
  float: right;
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
}

.slogan p {
  padding: 0;
  margin: auto;
  background-color: white;
  border: 0px;
  border-radius: 10px 10px 10px 10px;
  padding: 5px
}

.header_mid{
  float: right;
  height: 23%;
  width: 100%;
  background-color: lightgrey;
  border: 0px solid lightgrey;
  border-radius: 25px 10px 0 0;
}

.header_mid nav{
  float: right;
  height: 100%;
  width: 90%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
}

.mid_nav {
  width: 100%;
}

.mid_nav li {
  width: 15%;
  height: 100%;
  text-align: center;
}

.mid_nav a {
  color: #222;
  text-decoration: none;
  font-size: 12pt;
}

.mid_nav a:hover {
  color: darkgrey;
}

.header_bot {
  display: block;
  float: left;
  width: 100%;
  height: 20%;
  background-color: #222;
  border: 0px solid #222;
  border-radius: 10px 0 10px 10px;

}

.main_nav {
  width: 100%;
}

.header_bot nav {
  float: right;
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;

}

.main_nav li {
  width: 25%;
  height: 100%;
  text-align: center;
}

.main_nav a {
  color: lightgrey;
  text-decoration: none;
  font-size: 15pt;
}

.main_nav a:hover {
  color: darkgrey;
}
