h2, h3 {
  text-align: center;
}

.cert_stmnt {
  padding: 2.5%;
}

.temp_cont {
  height: 15em;
  width: 90%;
  margin: auto;
  background-color: lightgrey;
}

.text_box {
  float: left;
  max-width: 45%;
  padding: 2.5%
}

.solo_text_box {
  float: left;
  padding: 2.5%
}
