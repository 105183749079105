.dashboard-main {
    /* padding: 50px; */
    text-align: center;
    display: inline-block;
    width: 100%;
}

.dashboard-main p{
    font-size: 16pt;
    margin-bottom: 30px;
}

.dashboard-main ul{
    display: inline-block;
}

.dashboard-main li{
    float: left;
    list-style: none;
    text-align: center;
    background-color: #000000;
    margin-right: 30px;
    width: 150px;
    line-height: 60px;
}

.dashboard-main li a{
    text-decoration: none;
    color: #FFFFFF;
    display: block;
}

.dashboard-main li a:hover{
    text-decoration: none;
    color: #000000;
    background-color: #33B5E5;
}

.dashboard-main p {
    font-size: 10px;
    line-height: normal;
}

.dashboard-main p b {
    margin-left: 50px;
    line-height: 50px;
}

table {
    width: 100%;
}

.sdfQuestionRow td{
    font-size: 12px;
    margin: 10px;
    padding: 5px 20px;
}
.sdfQuestionHeader td {
    font: bold;
    font-size: 10px;
    margin: 10px;
    padding: 5px 20px;
}

tr{
    width: 100%;
}

.sdfQuestionGroup td{
    font-size: 18px;
}

.sdf-body {
    margin: 30px 50px;

}

.resultOutput {
    padding: 30px 80px;
    font-weight: bold;
}