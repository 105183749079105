.slide_bg {
  height: auto;
  width: auto;
}

.slide_container {
  height: 25em;
  width: 100%;

}

.slide_container p {
  margin: 0 auto;
}

.home_main {
  padding-left: 2.5%;
  padding-right: 2.5%;
}

h1 {
  text-align: center;
}
