.dashboard-main {
    /* padding: 50px; */
    text-align: center;
    display: inline-block;
    width: 100%;
}

.dashboard-main p{
    font-size: 16pt;
    margin-bottom: 30px;
}

.dashboard-main ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

}

.dashboard-main li{
    float: left;
    width: 300px !important;
    background-color: #337ab7 !important;
}

.dashboard-main li a{
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
    font-size: large !important;
}

.dashboard-main li a:hover{
    background-color: #111111;
}