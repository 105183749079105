.contact_page_title {
    width: 100%;
    text-align: center;
}

.contact_section_a {
    width: 45%;
    float: left;
    height: 100%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    margin-bottom: 1em;
}

.contact_section_b {
    width: 45%;
    float: left;
    height: 100%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    margin-bottom: 1em;
}

.map {
    margin: auto;
    margin-top: 2em;
    padding: 1%;
    height: 20em;
    width: 90%;
    box-shadow: 8px 12px 4px 0px rgba(0, 0, 0, 0.35);
    z-index: 1;
    background: #cfcfcf;

}

.map p {
  margin: auto;
}

.contact_section li, ol {
    padding-bottom: 10px;
    font-size: 12pt;
}

li p {
  font-size: 12pt;
}

.contact_form {
    width: auto;
    overflow: hidden;
}

.contact_field {
    width: 97.5%;
    margin-bottom: 20px;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #aaa;
    border-radius: 2px;
    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.35);
    z-index: 1;
}

.contact_submit {
    width: 150px;
}

.contact_form textarea {
    width: 97.5%;
    margin-bottom: 20px;
    padding: 5px;
    box-sizing: border-box;
    border: 1px solid #aaa;
    border-radius: 2px;
    box-shadow: 8px 8px 4px 0px rgba(0, 0, 0, 0.35);
    z-index: 1;
}
