.foot_container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
}

.top_footer-row {
  background: #222;
  border: 0px solid #222;
  border-radius: 15px 15px 0 0;
  width: 100%;
  min-width: 625px;
  height: 87.5%;
  overflow: hidden;
}

.center {
    width: 65%;
    min-width: 625px;
    margin-right: auto;
    margin-left: auto;
    background: red;
}


.foot-col {
    height: 100%;
    width: 25%;
    float: left;
}

.col-content {
    padding-left: 0;
    padding-right: 7.5%;
    padding-top: 15%;
    padding-bottom: 10%;
    min-width: 185px;
}

.col-content_last {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15%;
    padding-bottom: 10%;
    min-width: 185px;
}

.textwidget {
    margin-top: 20px;
}

.textwidget p, span {
    color: #f2f2f2;
    font-size: 10pt;
}

.textwidget a {
    color: #f2f2f2;
    font-size: 10pt;
    text-decoration: none;
}

.textwidget a:hover {
    color: darkorange;
    font-size: 10pt;
}

.foot-col p {
    padding: 0;
    margin: 0;
    color: #f2f2f2;
    text-transform: uppercase;
    font-size: 12pt;
}

.textwidget ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.textwidget li {
    margin: 0;
    padding: 0;
    padding-bottom: 2px;
}

.day p {
    text-transform: capitalize;
    padding: 0;
    margin: 0;
    font-size: 10pt;
}

.two-col1 {
    text-align: left;
    width: 40%;
    float: left;
    height: 100%;

}

.two-col2 {
    text-align: right;
    width: auto;
    float: left;
    height: 100%;
    margin-bottom: 3px;
}


.foot-nav {
    background: #ddd;
    height: 10%;
    width: auto;
    min-width: 625px;
    margin-bottom: .5em;
    border-bottom: #222 solid .5em;
}

.bot_center {
    height: 100%;
    width: 65%;
    min-width: 625px;
    margin-right: auto;
    margin-left: auto;
    vertical-align: middle;
}

.bot-col-a {
    width: 70%;
    float: right;
    height: 100%;
}

.bot-col-b {
    width: 30%;
    float: right;
    height: 100%;
}

.cr {
    float: left;
    width: auto;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
}

.cr p {
    font-size: 8pt;
    color: #333;
    margin: 0;
    padding: 0;
}

.foot_navbar {
    float: right;
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
}

.foot_navbar a {
    color: #333;
    font-size: 8pt;
    padding: 0;
    padding-left: 1em;
    margin: 0 auto;
    margin-left: 1em;

    text-decoration: none;
}

.foot_navbar a:hover {
    color: darkorange;
}
