.reg_page_title {
    width: 100%;
    text-align: center;
}

.reg_body {
  width: 70%;
  height: 100%;
  margin: 0 auto;

  margin-bottom: 5%;
}

.field_name_div {
  float: left;
  width: 30%;
  background-color: yellow;

}

.field_box_div {
  float: left;
  width: 70%;
  background-color: lightgreen;
}

.input-field input {
  width: 100%
}

.row {
  padding-top: .5em;
}

/*------------------------------------------*/



@font-face {
  font-family: "Roboto";
  src: local(Roboto Thin), url("./fonts/roboto/Roboto-Thin.woff2") format("woff2"), url("./fonts/roboto/Roboto-Thin.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "Roboto";
  src: local(Roboto Light), url("./fonts/roboto/Roboto-Light.woff2") format("woff2"), url("./fonts/roboto/Roboto-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: local(Roboto Regular), url("./fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("./fonts/roboto/Roboto-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: local(Roboto Medium), url("./fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("./fonts/roboto/Roboto-Medium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: local(Roboto Bold), url("./fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("./fonts/roboto/Roboto-Bold.woff") format("woff");
  font-weight: 700;
}
