.signin_page_title {
    width: 100%;
    text-align: center;
}


.sign_body {
  width: 50%;
  height: 100%;
  margin: 0 auto;

  margin-bottom: 5%;
}
