.container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
}

.main_content {
  width: 64%;
  min-width: 625px;
  margin: 0 auto;
  padding: 0;
  height: auto;
  background-color: white;
  overflow: hidden;
}

header {
  display: block;
  width: 100%;
  min-width: 625px;
  margin: 0 auto;
  padding: 0;
  height: 10em;
}

body {

  background-color: #202f50;
  margin: 0 auto;
  padding: 0;
  width: auto;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

nav li {
    float: left;
    margin: 0;
    padding-left: 0;
}


footer {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    height: 18em;
    background-color: inherit;
    overflow: hidden;
}
